<template>
    <div class="RawView d-flex">

        <DialogError data-app :errorDialog="errored"
                     title="Parece que estamos com alguns problemas"
                     message="Não foi possível carregar esta página agora. Tente novamente mais tarde..." />

        <Loading :pLoading="loading" />
        <div class="pa-3 w-100" id="main">
            <iframe :srcdoc="renderHtml"
                    id="renderHtml"
                    marginwidth=10
                    marginheight=30
                    frameborder=0 />
        </div>
    </div>
</template>

<script>
    import Loading from '@/shared/components/Loading.vue';
    import DialogError from '@/shared/components/DialogError.vue';

    export default {
        name: 'RawView',
        title: 'Visualização HTML - LegisOn',
        components: {
            Loading,
            DialogError,
        },
        data: () => {
            return ({
                idAto: null,
                renderHtml: null,
                errored: false,
                errorMessage: "",
                loading: true,
            })
        },
        methods: {
            getConstituicao: async function  () {
                console.log("oi");
                this.idAto = this.$route.params.numero;
                if (this.idAto !== null) {
                    this.$http.get('/Public/GetConstituicao')
                    .then(res => {
                        console.log(res.data);
                        let routecons = this.$route.query.atualizado === '1' ? true : false;
                        if (!routecons) {
                            this.renderHtml = res.data?.texto ? res.data.texto : "Não foi possível renderizar esse texto!";
                        }else {
                            this.renderHtml = res.data?.textoAtualizado ? res.data.textoAtualizado : "Não foi possível renderizar esse texto!";
                        }
                    })
                    .catch(error => {
                        this.errored = true;
                        this.errorMessage = error;
                        this.renderHtml = "";
                    })
                    .finally(this.loading = false);
                }
            },
            getAto: async function () {
                /*await this.$http.post("/Public/Consulta", this.getPostBody)
                    .then(response => {
                        let ato = response.data.result.find((ato) => ato.ato.numero == this.$route.params.numero);
                        this.idAto = ato.ato.id;
                    })
                    .catch(error => {
                        this.errored = true
                        this.errorMessage = error
                    });*/
                this.idAto = this.$route.params.numero;
                if (this.idAto !== null) {
                    await this.$http.get(`/Public/GetConteudoAto?atosIds=${this.idAto}`)
                        .then(response => {
                            let content = response.data?.content;
                            let routecons = this.$route.query.consolidado === '1' ? true : false;
                            if (content?.length) {
                                let cons = content.find(x => x.consolidado && x.disp);
                                let orig = content.find(x => !x.consolidado && x.disp);
                                if (routecons) {
                                    if (cons) {
                                        this.renderHtml = cons.textoFormatado ? cons.textoFormatado : "Não foi possível renderizar esse texto!";
                                    }else this.renderHtml = "Não foi possível renderizar esse texto!";
                                }else {
                                    if (orig) {
                                        this.renderHtml = orig.textoFormatado ? orig.textoFormatado : "Não foi possível renderizar esse texto!";
                                    }else this.renderHtml = "Não foi possível renderizar esse texto!";
                                }
                            }else this.renderHtml = "Não foi possível renderizar esse texto!";
                            this.loading = false
                        })
                        .catch(error => {
                            this.errored = true;
                            this.errorMessage = error;
                            this.renderHtml = "";
                        })
                        .finally(this.loading = false);
                }
            },
        },
        computed: {
            apiGetAto: function () {
                return `/Public/GetConteudoAto?atosIds=${this.$route.params.id}`
            },

            getPostBody: function () {
                let numeroAto = this.$route.params.numero;
                let idTipoAto = 0;
                if (this.$enumTipoAto)
                    idTipoAto = Object.keys(this.$enumTipoAto) // Com o nome da rota se obtem o id do ato - ex: lei complementar
                                      .find(key => this.$enumTipoAto[key] === this.$route.params.tipoato);

                return {
                    "Numero": numeroAto,
                    "PalavrasChave": "",
                    "Ementa": "",
                    "IdTema": 0,
                    "IdIniciativa": 0,
                    "IdOrgao": 0,
                    "IdTipo": idTipoAto,
                    "IdUsuarioCriador": 0,
                    "Page": 1
                }
            }
        },
        mounted: async function () {
          if (!this.$enumTipoAto) {
            await this.$http.get("/TipoAto/Get")
              .then(response => {
                this.$enumTipoAto = response.data.reduce(function (result, item) {
                  let key = item[Object.keys(item)[0]];
                  let value = item[Object.keys(item)[1]].replace(/\s/g, "-")                // remove os espaços
                    .toLowerCase()                      // converte em minusculo
                    .normalize("NFD")                   // disassocia acentos de letras
                    .replace(/[\u0300-\u036f]/g, "");   // remove os acentos

                  result[key] = value;
                  return result;
                }, {});
              })
              .catch(error => {
                throw error;
              });
          }
          if (this.$route.params.atoContent) {
              this.renderHtml = this.$route.params.atoContent.conteudo.texto
              this.loading = false;
          } else {
            if (this.$route.params.tipoato === 'constituicao') {
                this.getConstituicao();
            }else 
              this.getAto();
          }
      }
    }
</script>

<style>
    @import './RawView.css';
</style>
